import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const ReasonsKeepMoisture = () => (
  <Layout>
    <SEO
      title="5 Reasons to Keep Your Hair Moisturised"
      description="When it comes to natural hair care (especially in thicker and curlier hair types), moisture control is up there with what should be one of your highest priorities."
    />
    <h1>5 Reasons to Keep Your Hair Moisturised</h1>

    <p>When it comes to natural hair care (especially in thicker and curlier hair types), moisture control is up there with what should be one of your highest priorities. It’s not hard to imagine that well hydrated hair is at its healthiest and most protected. Moisturised hair looks great, is generally far easier to manage and there are a whole lot of other benefits too and we’ll get onto those later.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/eGLFwvQ.jpg"
        alt="reasons to keep yor hair moisturised"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/@dsinoca"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Daniel Sinoca</a></p>
    </div>

    <p>Think about all of the products that are marketed to us and what they aim to achieve. Conditioners, oils, edge control etc. All of these things promise to work their magic mainly by locking in or adding moisture to our hair.</p>
    <p>Here are the benefits of moisture for our hair</p>

    <h2>1. Less Damage</h2>
    <p>One of the best advantages is that our hair becomes less prone to damage. Damage from the environment weakens our hair and makes it far easier to get split ends and weaker strands which leads to breakage. Hydration keeps your hair strong so when it is exposed to harsh temperatures and conditions, it has a barrier of protection. Then there’s the damage from any heat you might be applying yourself. Hydrated hair before you treat your hair will make it able to battle permanent damage a lot better.</p>

    <h2>2. More Growth</h2>
    <p>Moisturised hair means more growth. If we look at the opposite state and imagine your hair being dried out. It will be more brittle and prone to damage because it is exposed to the elements. Moisture provides a barrier of protection that your hair needs. Less damage and breakage means healthier hair which has the potential to grow longer.</p>
    <p>If you can imagine that dry skin can crack and get damaged much more easily when it is dried out, hair behaves in a similar way. The moisture also helps your hair retain its nutrients because they are not being wasted away by the environment.</p>

    <h2>3. Better Look and Feel</h2>
    <p>I don’t need to speak much about the look and feel. Imagine your hair dried out and brittle then imagine it hydrated and soft. Which one would you prefer the look and feel of? Whether you dress and keep a nice appearance for yourself or others, having hair that looks and feels good can’t hurt in any way. In fact, after tips for growing hair, getting beautiful looking and soft hair are the next most popular things we get approached for advice on.</p>

    <h2>4. Easier Detangling</h2>
    <p>Whether you use a dedicated detangling product or not, we can all agree that having conditioned and hydrated hair makes the whole detangling process a lot easier. Hydration allows the comb to travel through the hair a lot easier because there is more slip. Hydrated hair is also much less prone to tangling in the first place, high friction is what causes the hair to wrap around itself and tie in knots when it rubs together during the day. When you eventually do detangle your hair, more knots means more potential to pull hair out. Leading to thinner hair and less visible growth.</p>

    <h2>5. Better Curl Definition</h2>
    <p>With moisture, your hair will be in the right state for your curls to show fully. This is because the hair strands will stick together in the perfect amount naturally. I’m sure you’ve noticed that when there isn’t enough moisture hair separates in a bad way and tries to exist as single strands. This is where frizz and loss of curl patterns. On the other hand, if you have too much moisture, your hair can clump together too much and you won’t be able to see your curls that way either.</p>
    <p>I have to say that if you have really curly hair i.e. kinky or coily, you probably won’t be able to style your hair in a way that your natural curl pattern can be seen from afar. If you want larger curls, you will have to use a technique in conjunction with moisturising like doing a twist out or braid out. It’s more effort but you can make it work.</p>

    <h2>Final note</h2>
    <p>How much moisture you need all depends on you as an individual and the type of hair you have. Generally, if your hair is thicker and more curly, it will require more hydration to get a result you are happy with. Whereas people with thinner and less curly hair types can get a good amount of hydration with far less product.</p>



    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p>             
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default ReasonsKeepMoisture
